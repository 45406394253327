<template>
    <el-carousel height="25vh">
        <el-carousel-item v-for="(item,i) in list" :key="item">
            <div class="banner" @click="getbanner(i)">
                <img :src="item" alt="">
            </div>
        </el-carousel-item>
    </el-carousel>
</template>
<script>
export default {
    data() {
        return {
            list: [
                require('@/assets/image/banner.png'),
                require('@/assets/image/banner1.png'),
                require('@/assets/image/banner2.png'),
            ]
        }
    },
    methods:{
        getbanner(i){
           this.$emit('getbanner',i)
        }
    }
}
</script>
<style>
.banner {
    width: 118rem;
    height: 25vh;
}

.banner img {
    width: 100%;
    height: 100%;
}
</style>