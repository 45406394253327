<template>
    <div id="keyboard">
        <div v-if="freenum >= 0" class="write_num">
            免费次数0{{ `免费次数${freenum}` }}
        </div>
        <div class="keyboard">
            <el-input type="textarea" :autosize="{ minRows: 2, maxRows: 4 }" v-model="question"
                :placeholder="placeholder" @keydown.native="handleKeyCode($event)"></el-input>
            <img :src="nextQuestion ? require('@/assets/image/send1.png') : require('@/assets/image/send.png')"
                @click="send" alt="">
        </div>
    </div>
</template>

<script>
import api from "@/api/api";
export default {
    props: {
        tips: {
            type: String,
            default: "请输入你的问题"
        },
        nextQuestion: {
            type: Boolean,
            default: true
        }
    },
    created() {
        this.degree()
    },
    data() {
        return {
            question: '', //问题
            placeholder: `${this.tips},*Enter发送 *Ctr1+Enter换行`, //提示词
            islogin: false, // 是否登录
            freenum: -2, //免费次数
            sendImg: [],
        }
    },
    methods: {
        // 键盘输入
        handleKeyCode(event) {
            if (event.keyCode == 13) {
                if (this.nextQuestion) {
                    this.$message({
                        message: '请等待问题回答完毕，或者手动停止！',
                        type: 'warning'
                    });
                    return
                }
                if (!event.ctrlKey) {
                    this.send()
                    event.preventDefault();
                } else {
                    this.question += '\n';
                }
            }
        },
        // 提问
        send() {
            if (!this.islogin) {
                this.$emit('login')
                return
            }

            if(this.freenum == 0){
                this.$message({
                    message: '免费次数已用尽！',
                    type: "warning",
                });
                this.$router.push({ path: '/vip' });
                return
            }

            if (this.nextQuestion) {
                this.$emit('stopTalk')
                return
            }

            if (this.question == '') {
                this.$message({
                    message: '请输入问题!',
                    type: 'warning'
                });
                return
            }
            this.$emit("putQuestion", this.question);
            this.question = ''
        },
        degree() {
            api.degree().then((res) => {
                // console.log(res)
                if (res == 401) {
                    this.islogin = false
                } else if (res.code == 200) {
                    this.islogin = true
                    this.freenum = res.data
                }
            })
        }
    }
}
</script>

<style>
#keyboard {
    width: 100%;
    /* background-color: #409EFF; */
    /* display: flex;
    justify-content: center; */
}

.write_num {
    width: 7rem;
    height: 2rem;
    line-height: 2rem;
    font-size: 1rem;
    text-align: center;
    margin-bottom: 0.5rem;
    border-radius: 0.8rem 0.8rem 0.8rem 0;
    color: #FFFFFF;
    background-color: #409EFF;
}

.keyboard {
    width: 100%;
    min-height: 7vh;
    background: #FFFFFF;
    box-shadow: 0px 0px 8px 0px rgba(64, 158, 255, 0.4);
    border-radius: 1rem 1rem 1rem 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem;
}

.el-textarea__inner {
    border: none !important;
    scrollbar-width: none;
    /* Firefox */
    font-size: 1.2rem;
}

.el-textarea__inner::-webkit-scrollbar {
    display: none;
}

.keyboard img {
    width: 4.125rem;
    height: 3rem;
    margin-left: 1rem;
}
</style>