<template>
    <div id="set_box">
      <el-popover placement="right" trigger="click" popper-class="el-set">
        <div>QQ:2199170632</div>
        <div>邮箱:hbsruiying@163.com</div>
        <div class="set_box" style="margin-bottom: 1rem;" slot="reference">
          <img src="@/assets/image/set1.png" alt="" />
          <div class="set_text">联系我们</div>
        </div>
      </el-popover>
  
      <div
        class="set_box"
        v-for="(item, i) in list"
        :key="i"
        @click="setclick(i)"
      >
        <img :src="item.img" alt="" />
        <div class="set_text">{{ item.text }}</div>
      </div>
    </div>
  </template>
  <script>
  import data from "@/assets/data.js";
  import api from "@/api/api.js";
  export default {
    data() {
      return {
        list: data.data.set,
        visible: false,
      };
    },
    methods: {
      // 退出登录
      setclick(i) {
        console.log(i);
        if (i == 2) {
          this.$confirm("是否退出登录？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              api.logout().then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: "退出成功！",
                    type: "success",
                  });
                  localStorage.removeItem("token_key");
                  location.reload();
                }
              });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消",
              });
            });
        }
        if (i == 0) {
          window.open("https://ai.hbsry.com/protocol/yhxy.html");
        }
        if (i == 1) {
          window.open("https://ai.hbsry.com/protocol/ysxy.html");
        }
      },
    },
  };
  </script>
  <style>
  #set_box {
    padding: 1rem 1.2rem;
  }
  .set_box {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }
  .set_box:last-child {
    margin: 0;
  }
  .set_box img {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 1rem;
  }
  .set_text {
    width: 100%;
    height: 3rem;
    line-height: 3rem;
    font-family: Microsoft YaHei;
    font-weight: 400;
    font-size: 1.2rem;
    color: #333333;
    border-bottom: 1px solid #e9e9e9;
    cursor: pointer;
  }
  .el-popover.el-popper.el-set {
    min-width: 15rem !important;
    width: 20rem !important;
    padding: 1rem !important;
    font-size: 1.5rem;
  }
  </style>