<template>
    <div class="create_left">
        <div class="create_left1" @click="toHome">返回</div>

        <div class="create_left2">
            <div class="create_left3" v-for="(item, i) in list" :key="i">
                <div class="create_left4" :style="{ color: lableIndex[0] == i ? '#409EFF' : '#666666' }"
                    @click="choicei(i)">
                    {{ item.title }}
                    <img v-if="lableIndex[0] == i" :src="lablei == i ? createImg[3] : createImg[2]" alt="">
                    <img v-else :src="lablei == i ? createImg[1] : createImg[0]" alt="">
                </div>
                <div v-if="lablei == i" class="create_left7">
                    <div :class="lableIndex[0] == i && lableIndex[1] == l ? 'create_left5 create_left6' : 'create_left5'"
                        v-for="(lable, l) in item.lable" :key="l" @click="choice(i, l)">
                        <img :src="lable.pcIcon ? lable.pcIcon : lable.icon" alt="">
                        {{ lable.name }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';
import api from "@/api/api";
import { EventBus } from '@/utils/eventBus.js';
export default {
    props: {
        lable: {
            type: Object,
            default: {}
        }
    },
    mounted() {
      EventBus.$on('anwserStart', () => {
        this.ask = true
      })
      EventBus.$on('anwserOver', () => {
        this.ask = false
      })
      
    },
    beforeDestroy() {
      EventBus.$off('anwserStart');
      EventBus.$off('anwserOver');
    },
    data() {
        return {
            ask:false,
            createImg: data.data.createImg,
            lableIndex: [],
            lablei: 0,
            remArry: [{ type: "aigw" }, { type: "pcdnb" }], //pc端没有的
            typeList: ['work', 'education', 'writing', 'study', 'recreation'],
            list: [{
                title: '热门创作',
                lable: data.data.homeHot
            },
            {
                title: '会员专区',
                lable: []
            },
            {
                title: '工作办公',
                lable: []
            },
            {
                title: '教育',
                lable: []
            },
            {
                title: '媒体写作',
                lable: []
            },
            {
                title: '学习',
                lable: []
            },
            {
                title: '娱乐',
                lable: []
            }]
        }
    },
    created() {
        this.getHome()
        this.typeList.forEach((item, i) => {
            this.getWork({ module: item, index: i });
        });
    },
    methods: {
        // 返回
        toHome() {
            if(this.ask){
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            this.$router.push({ path: '/' });
        },
        getHome() {
            api.getHome().then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.list[0].lable = this.list[0].lable.concat(this.removeDuplicates(this.remArry, res.data.hots));
                    this.list[1].lable = this.list[1].lable.concat(this.removeDuplicates(this.remArry, res.data.vip));
                    this.getIndex()
                }
            })
        },
        getWork(params) {
            api.getWork({
                module: params.module
            }).then((res) => {
                // console.log(res)
                if (res.code == 200) {
                    this.list[2 + params.index].lable = this.replaceIcon(res.data)
                    this.getIndex()
                }
            });
        },
        getIndex(){
            this.list.map((item, i) => {
                if (item.title == this.lable.title) {
                    this.lablei = i
                    this.lableIndex[0] = i
                    item.lable.map((lable, l) => {
                        if (lable.name == this.lable.name) {
                            this.lableIndex[1] = l
                        }
                    })
                }
            })
        },

        // 替换添加图标
        replaceIcon(data) {
            // data.map((item) => {
            //     if (item.name == '公文写作') {
            //         item.icon = require(`@/assets/icon/${item.type}.png`)
            //     }
            // })
            return data
        },
        // 删除数组中元素
        removeDuplicates(arr1, arr2) {
            const type = arr1.map((item) => item.type);
            return arr2.filter((item) => !type.includes(item.type));
        },
        // 修改i值
        choicei(i) {
            if (i == this.lablei) {
                this.lablei = -1
                return
            }
            this.lablei = i
        },
        // 选择lable
        choice(i, l) {
            if(this.ask){
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            this.lableIndex = [i, l]
            // console.log(this.list[i].lable[l])
            this.$emit('getlable',{
                title:this.list[i].title,
                name:this.list[i].lable[l].name,
                type:this.list[i].lable[l].type
            })
        }
    }
}
</script>

<style>
.create_left {
    width: 14rem;
    height: 90vh;
    background: #FFFFFF;
    border-radius: 0 0.5rem 0.5rem 0;
    padding: 1.5vh 0;
}

.create_left1 {
    width: 11rem;
    height: 4vh;
    line-height: 4vh;
    text-align: center;
    font-size: 1.2rem;
    color: #409EFF;
    border: 1px solid #409EFF;
    margin: 0 auto;
    border-radius: 0.5rem;
    cursor: default;
    margin-bottom: 1.5vh;
}

.create_left2 {
    width: 14rem;
    height: 94%;
    border-top: 1px solid #BBBBBB;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: #409EFF; */
}

.create_left3 {}

.create_left4 {
    width: 11rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    font-weight: 700;
    padding: 0 0.8rem;
    cursor: default;
    /* color: #666666; */
    margin: 1.2rem auto;
    /* background-color: #409EFF; */
}

.create_left4 img {
    width: 2rem;
    height: 2rem;
}

/* .create_left7{
    margin-bottom: 1.2rem;
} */

.create_left5 {
    width: 12rem;
    display: flex;
    padding: 0.9rem 0.8rem;
    align-items: center;
    color: #666666;
    font-size: 1.15rem;
    border: 1px solid #E7F0FF;
    border-radius: 0.3rem;
    margin: 0 auto;
    margin-bottom: 0.8rem;
    cursor: default;
}

.create_left5 img {
    width: 1.6rem;
    margin-right: 0.8rem;
}

.create_left6 {
    border: 1px solid #409EFF;
    background: #CBE5FF;
    border-left: 3px solid #409EFF;
}
</style>