<template>
    <div class="createContent2">
        <cardTxt ref="upIxt" :disabled="disabled"></cardTxt>
        <cardBox :content="content[0]" @getContent="getContent" :disabled="disabled"></cardBox>
    </div>
</template>

<script>
import cardBox from '@/components/card-box.vue'
import cardTxt from '@/components/card_txt.vue';
export default {
    components: {
        cardBox, cardTxt
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    created() {

    },
    data() {
        return {
            content: [
                {
                    type: 'product',
                    title: "对文档提问",
                    placeholder: '请输入对文档的提问，例如:生成一份总结报告、 生成一份营销策划报告。',
                }
            ],
            //   请求信息
            question:''

        }
    },
    methods: {
        getContent(i) {
            this.question = i
        },
        upIxt() {
            this.$refs.upIxt.submitUpload(this.question)
        }
    }
}
</script>

<style>

</style>