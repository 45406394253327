import Vue from 'vue'
import App from './App.vue'
// ElementUI
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入router
import router from './router/router'
// 引入axios
import axios from 'axios'
// 复制
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard)
Vue.use(ElementUI);
Vue.prototype.axios = axios;
Vue.config.productionTip = false

// 引入rem
import './utils/rem'

new Vue({
  el: '#app',
  router,
  render: function (h) { return h(App) }
}).$mount('#app')