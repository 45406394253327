<template>
    <div class="create_result4_1">
        <div class="create_result4_5">

            <div class="create_result4_5_2">
                <div>评分:</div>
                <div class="create_result4_5_3">{{ total }} 分</div>
            </div>

            <div class="create_result4_5_1">
                <div class="create_result4_6" v-for="(item, i) in score" :key="i">
                    <div class="create_result4_7">
                        {{ item.title }}
                    </div>
                    <div class="create_result4_8" :style="{color: 
                    Number(item.fraction) >= 0 && Number(item.fraction) <= 3 ? '#FF4B32'
                    : Number(item.fraction) >= 4 && Number(item.fraction) <= 6 ? '#FDBD19'
                    : Number(item.fraction) >= 7 && Number(item.fraction) <= 9 ? '#0599FF'
                    : Number(item.fraction) == 10 ? '#13DEC6' : ''}">
                        {{ item.fraction }}
                    </div>
                    <div class="create_result4_9">
                        /10分
                    </div>
                </div>
            </div>

        </div>
    </div>

</template>
<script>
export default {
    props: {
        score: {
            type: Array,
            default: []
        }
    },
    created() {
        this.getTotal()
    },
    data() {
        return {
            total: 0,
        }
    },
    methods: {
        getTotal() {
            this.score.forEach(item => {
                this.total += item.fraction
            })
        }
    }
}

</script>
<style>
.create_result4_1 {
    width: 80rem;
    height: 72vh;
    margin: 0 auto;
    padding-top: 3vh;
    /* background-color: aqua; */
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: aqua; */
}

.create_result4_5 {
    width: 80rem;
    padding: 2rem 0;
    border-radius: 1rem;
    background-color: #FFFFFF;
}

.create_result4_5_1 {
    width: 70rem;
    margin: 0 auto;
    background-color: #DEF2FF;
    border: 1px solid #059CFF;
    border-radius: 0 0 0.5rem 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 1.5rem 0 0.5rem;
}

.create_result4_5_2 {
    width: 70rem;
    margin: 0 auto;
    margin-bottom: 10px;
    font-size: 1rem;
    font-weight: bold;
    display: flex;
}

.create_result4_5_3 {
    color: #FF1F00;
    margin-left: 0.5rem;
}

.create_result4_6 {
    width: 13rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333333;
    margin-bottom: 1rem;
}

.create_result4_7 {
    font-size: 1.05rem;
    font-weight: 400;
    margin-right: 0.5rem;
}

.create_result4_9 {
    font-size: 1rem;
}
</style>