<template>
    <div class="create_result4_1">
        <div class="create_result4_2" v-for="(item, i) in evaluate" :key="i">
            <div class="create_result4_3">
                {{ item.title }}
            </div>
            <div class="create_result4_4">
                {{ item.content }}
            </div>
        </div>
    </div>

</template>
<script>
export default {
    props:{
        evaluate:{
            type:Array,
            default:[]
        }
    },
    data() {
        return {}
    }
}

</script>
<style>
.create_result4_1 {
    width: 80rem;
    height: 72vh;
    margin: 0 auto;
    padding-top: 3vh;
    /* background-color: aqua; */
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: aqua; */
}

.create_result4_2 {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.create_result4_3 {
    font-size: 1.2rem;
    font-weight: 700;
    margin-bottom: 1rem;
}
</style>