<template>
    <div class="create_result">
        <div class="create_result1">

            <div class="create_result2_1" v-if="lable.name == '作文批改'">
                <div v-for="(item, i) in writeLable" :key="i" @click="changwrite(i)"
                    :class="writeIndex == i ? 'create_result2_2 create_result2_3' : 'create_result2_2'">{{ item }}
                    <div v-if="writeIndex == i" class="create_result2_4"></div>
                </div>

            </div>
            <div class="create_result2" v-else>
                创作结果
            </div>
            <div class="create_result3" @click="openHistory">
                历史记录
                <img src="../assets/image/create_icon5.png" alt="">
            </div>
        </div>
        <!-- 作文批改 -->
        <div class="create_result4" v-if="lable.name == '作文批改'">
            <nodata v-if="!writeShow"></nodata>
            <div v-else>
                <div v-if="writeIndex == 0">
                    <createEvaluate :evaluate="evaluate"></createEvaluate>
                </div>
                <div v-if="writeIndex == 1">
                    <createScore :score="score"></createScore>
                </div>
                <createBottom @polishing="polishing" :composition="false"></createBottom>
            </div>

        </div>

        <!-- 其他 -->
        <div class="create_result4" v-else>
            <nodata v-if="requestDatas.length == 0"></nodata>
            <div v-else>
                <createResultcontent ref="resultcontent" :lable="lable" :requestDatas="requestDatas">
                </createResultcontent>
                <createBottom @bottomBTn="bottomBTn"></createBottom>
            </div>
        </div>
    </div>
</template>

<script>
import nodata from './nodata.vue';
import createBottom from './create_bottom.vue'
import createResultcontent from './create_resultcontent.vue'
import createEvaluate from './create_evaluate.vue';
import createScore from './create_score.vue';
import data from '@/assets/data';
import { EventBus } from "@/utils/eventBus";
export default {
    components: {
        nodata, createBottom, createResultcontent, createEvaluate, createScore
    },
    props: {
        lable: {
            type: Object,
            default: {}
        }
    },
    created() {
        // console.log(this.lable)
    },
    mounted() {
        // 监听作文批改
        EventBus.$on('correct', (data) => {
            // console.log(data)
            this.writeShow = true
            this.evaluate[0].content = data.summary
            this.evaluate[1].content = data.advantage
            this.evaluate[2].content = data.recommend
            this.writeId = data.id
            this.score.forEach(item => {
                for (const key of Object.keys(data)) {
                    const value = Object(data)[key]
                    if (key === item.type) {
                        item.fraction = value
                    }
                }
            })
        });

        // 监听文档理解
        EventBus.$on('wdljquestion', (data) => {
            let detailInfo = []
            let obj = {
                title: "文档理解",
                content: data.question
            }
            detailInfo.push(obj)
            this.requestDatas.push(detailInfo)
            setTimeout(() => {
                this.$refs.resultcontent.wdlj(data)
            }, 50)
        })
    },
    beforeDestroy() {
        EventBus.$off('wdljquestion');
        EventBus.$off('correct'); // 确保移除事件监听器以避免内存泄漏
    },
    data() {
        return {
            requestDatas: [],
            anwser: '', //流式回答
            writeShow: false,
            writeLable: ['综合评价', '批改评分'], //批改作文标题
            writeIndex: 0, //批改作文index
            evaluate: data.data.evaluate, //综合评价
            score: data.data.score, //评分
            writeId: 0, //批改作文id
        }
    },
    methods: {
        bottomBTn(i) {
            if (i == 0) {
                //下载
                this.$refs.resultcontent.downloadText()
            } else if (i == 1) {
                // 复制
                this.$refs.resultcontent.copyText()
            } else if (i == 2) {
                // 分享
                this.$refs.resultcontent.shareText()
            } else if (i == 3) {
                // 分享
                this.$refs.resultcontent.stopText()
            }
        },
        //打开历史记录
        openHistory() {
            if (this.lable.name == '作文批改') {
                this.$emit('openHistory', 1)
            } else {
                this.$emit('openHistory')
            }
        },
        getrequest(item) {
            this.requestDatas = item
            if (this.requestDatas.length !== 0) {
                setTimeout(() => {
                    this.$refs.resultcontent.getAnwser(item[1])
                    // this.$refs.resultcontent.stopTalk()
                }, 50)
            }
            // console.log(item)

        },
        changwrite(i) {
            this.writeIndex = i
        },
        // 一键润色
        polishing() {
            this.$emit('polishing', this.writeId)
        }

    }
}

</script>

<style>
.create_result {
    width: 82rem;
    height: 90vh;
    background-color: #ffffff8c;
    border-radius: 0 0.5rem 0.5rem 0;
}

.create_result1 {
    width: 100%;
    height: 7vh;
    font-size: 1rem;
    color: #111111;
    cursor: default;
    padding-top: 2px;
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0 0.5rem 0 0;
    position: relative;
    font-size: 1rem;
    color: #111111;

}

.create_result2_1 {
    position: relative;
    /* background-color: aqua; */
    width: 18rem;
    display: flex;
    justify-content: space-between;
}

.create_result2_2 {
    font-weight: normal;
}

.create_result2_3 {
    font-weight: 700;
}

.create_result2_4 {
    position: absolute;
    width: 4rem;
    height: 0.4rem;
    background-color: #409EFF;
    bottom: -0.8rem;
    border-radius: 0.4rem;
}

.create_result3 {
    display: flex;
    align-items: center;
    position: absolute;
    right: 2rem;
    color: #666666;
}

.create_result3 img {
    width: 1.4rem;
    margin-left: 0.4rem;
}

.create_result4 {
    width: 82rem;
    height: 83vh;
    position: relative;
    border-radius: 0 0 0.5rem 0;
}
</style>