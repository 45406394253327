<template>
    <div class="createContent2">
        <cardBox :content="content[0]" @getContent="getContent" :disabled="disabled"></cardBox>
        <cardBox :content="content[1]" @getContent="getContent1" :disabled="disabled"></cardBox>
    </div>
</template>

<script>
import cardBox from '@/components/card-box.vue'
export default {
    components: {
        cardBox
    },
    props: {
        pageContent: {
            type: Object,
            default: {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    created() {

    },
    data() {
        return {
            content: [
                {
                    type: 'station',
                    title: "岗位",
                    placeholder: this.pageContent.station,
                },
                {
                    type: 'content',
                    title: "会议内容",
                    placeholder: this.pageContent.content,
                }
            ],
            //   请求信息
            contentInfo: {
                station: "",
                content:''
            },

        }
    },
    methods: {
        getContent(i) {
            this.contentInfo.station = i
        },
        getContent1(i) {
            this.contentInfo.content = i
        },
        getInfo() {
            // 判断请求信息是否为空 
            let type = Object.keys(this.contentInfo).map(key => {
                if (this.contentInfo[key] == '') {
                    return key
                }
            })
            type = type.filter(item => item !== undefined)[0]

            for (let item of this.content) {
                if (item.type == type) {
                    this.$message({
                        message: `请输入${item.title}`,
                        type: "warning",
                    });
                    return
                }
                if (item.type == 'length') {
                    if (Number(this.contentInfo.length) > Number(this.pageContent.length) || Number(this.contentInfo.length) <= 0) {
                        this.$message({
                            message: `请输入正确字数`,
                            type: "warning",
                        });
                        return
                    }
                }
            }

            // 展示数据添加
            let detailInfo = []
            Object.keys(this.contentInfo).map(key => {
                let obj = {}
                for (let item of this.content) {
                    if (item.type == key) {
                        obj.title = item.title
                        obj.content = this.contentInfo[key]
                    }
                }
                detailInfo.push(obj)
            })
            let Arry = []
            Arry.push(detailInfo);
            Arry.push(this.contentInfo);

            return Arry
        }
    }
}
</script>

<style>

</style>