<template>
    <div id="home_left">
        <div class="home_left1" v-for="(item, i) in left.slice(0, 4)" @click="chang(i)">
            <img :src="item.icon" alt="">
            <div class="home_left2">{{ item.name }}</div>
        </div>

        <div class="home_left1" v-popover:morePop>
            <img :src="left[4].icon" alt="">
            <div class="home_left2">{{ left[4].name }}</div>

            <!-- 更多 -->
            <el-popover placement="right" ref="morePop" popper-class="el-popover-more">
                <img class="more_img" src="../assets/image/more1.png" alt="">
                <div class="more_text">更多功能陆续更新中尽请期待！</div>
            </el-popover>
        </div>

        <div class="home_left1" v-popover:setPop>
            <img :src="left[5].icon" alt="">
            <div class="home_left2">{{ left[5].name }}</div>

            <!-- 设置弹框 -->
            <el-popover placement="right" width="160" ref="setPop" popper-class="el-popover-self">
                <set></set>
            </el-popover>
        </div>

    </div>
</template>

<script>
import data from '@/assets/data.js'
import set from '@/components/set.vue'
export default {
    components:{
        set
    },
    data() {
        return {
            left: data.data.homeLift,
            visible: true,
        }
    },
    methods: {
        chang(i) {
            if (i == 0) {
                this.$router.push({ path: '/' });
            } else if (i == 1) {
                this.$router.push({ path: '/talk' });
            } else if (i == 2) {
                this.$router.push({ path: '/adviser' });
            } else if (i == 3) {
                this.$emit('openmyCreate')
            }
        }
    }
}
</script>

<style>
#home_left {
    width: 6rem;
    height: 90vh;
    background-color: #FFFFFF;
    border-radius: 0.5rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    padding: 1.5vh 0;
    position: relative;
}

.home_left1 {
    width: 6rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    margin-bottom: 1.2vh;
    cursor: pointer;
    /* background-color: aqua; */
}

.home_left1 img {
    width: 3.5rem;
    margin-bottom: 0.4rem;
}

.home_left2 {
    font-size: 1.2rem;
}

.home_left1:last-child {
    position: absolute;
    bottom: 0;
}

.el-popover-more {
    min-width: 10rem !important;
    width: 12rem !important;
    padding: 1rem !important;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.more_img {
    width: 9rem;
    height: 6rem;
}

.more_text {
    width: 10rem;
    font-size: 1.2rem;
    color: #333333;
}
.el-popover.el-popper.el-popover-self {
  min-width: 13rem !important;
  width: 16rem !important;
  padding: 0;
}
</style>