<template>
    <div class="nodata">
        <img :src="noimg" :style="{width:width + '%'}" alt="">
        <div class="nodata1">{{ notext[0] }}</div>
        <div class="nodata1">{{ notext[1] }}</div>
    </div>
</template>

<script>

export default {
    props:{
        width:{
            type:String,
            default:'20'
        },
        notext:{
            type:Array,
            default: () => ['创作结果在这里展示', '请完善你要生成的内容吧']
        },
        noimg:{
            type:String,
            default:require('@/assets/image/nodata.png')
        }
    },
    data() {
        return {
          
        };
    },
    methods:{
        
    }
}
</script>

<style>
.nodata{
    width: 80%;
    margin: 0 auto;
    text-align: center;
    font-size: 1rem;
    padding-top: 15rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    /* background-color: aqua; */
}
.nodata1{
    font-size: 1rem;
    color: #666666;
    width: 13rem;
    margin-top: 0.3rem;
}
</style>