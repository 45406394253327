<template>
  <div id="create">
    <top index="0" @login="login"></top>
    <div class="create_content">
      <createLeft :lable="lable" @getlable="getlable"></createLeft>

      <div class="create_content1">
        <createContent ref="createcontent" :lable="lable" @getInfo="getInfo" @login="login"></createContent>
        <createResult ref="createresult" @openHistory='openHistory' @polishing="polishing" :lable="lable">
        </createResult>
      </div>
    </div>
    <login ref="login"></login>
    <myCreate ref="myCreate"></myCreate>
    <polishingPopup ref="polishing"></polishingPopup>
    <loading v-if="loadShow"></loading>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import login from '@/components/login.vue';
import createLeft from '@/components/create_left.vue';
import createContent from '@/components/create_content.vue';
import createResult from '@/components/create_result.vue'
import myCreate from '@/components/my_create.vue'
import polishingPopup from '@/components/polishing_popup.vue';
import loading from '@/components/loading.vue'
import { EventBus } from "@/utils/eventBus";
export default {
  components: {
    top, login, createLeft, createContent, createResult, myCreate, polishingPopup, loading
  },
  created() {
    // console.log(this.$route.query)
    this.lable = this.$route.query
  },
  mounted() {
    // 监听作文批改
    EventBus.$on('loading', (data) => {
      // this.$refs.loading.show(data)
      this.loadShow = data
    });
  },
  beforeDestroy() {
    EventBus.$off('loading'); // 确保移除事件监听器以避免内存泄漏
  },
  data() {
    return {
      lable: {}, //标题
      loadShow:false,
    }
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 打开历史
    openHistory(item) {
      if (item) {
        this.$refs.myCreate.show(1)
        return
      }
      this.$refs.myCreate.show()
    },
    // 获取请求参数
    getInfo(item) {
      this.$refs.createresult.getrequest(item)
    },
    getlable(item) {
      this.lable.title = item.title
      this.lable.name = item.name
      this.lable.type = item.type
      this.$refs.createcontent.getPrompt(item.type)
      this.getInfo([])
    },
    // 一键润色
    polishing(id) {
      this.$refs.polishing.show(id)
    }

  }
}
</script>

<style>
#create {
  width: 128rem;
  height: 100vh;
  background: url('@/assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.create_content {
  width: 128rem;
  height: 90vh;
  margin-top: 1vh;
  display: flex;
  justify-content: space-between;
}

.create_content1 {
  width: 112rem;
  display: flex;
}
</style>