<template>
    <div class="dialogue">
        <!-- 示例 -->
        <div class="dialogue_left" v-if="cueword">
            <img :src="head" alt="">
            <div class="dialogue_left1" :style="{ maxWidth: max + '%' }">
                {{ cueword }}
            </div>
        </div>

        <div v-for="(item, i) in dialogueList" :key="i">
            <!-- 提问 -->
            <div class="dialogue_right">
                <div class="dialogue_right1" :style="{ maxWidth: max + '%' }">
                    {{ item.question }}
                </div>
                <img src="../assets/image/head.png" alt="">
            </div>

            <!-- 回答 -->
            <div class="dialogue_left">
                <img :src="head" alt="">
                <div class="dialogue_left1" :style="{ maxWidth: max + '%' }">
                    <div v-if="item.id != null" v-html="item.anwser"></div>
                    <Typewriter v-else ref="typewriter"  :content="item.anwser" @anwserOver="anwserOver"></Typewriter>
                    <div class="dialogue_left2">(当前结果为AI合成，仅供参考)</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Typewriter from './typewriter.vue';
export default {
    props: {
        max: {
            type: Number,
            default: 92.4
        },
        // 提示词
        cueword: {
            type: String,
            default: ''
        },
        // 头像
        head: {
            type: String,
            default: ''
        }
    },
    components:{
        Typewriter
    },
    data() {
        return {
            dialogueList: [],
        }
    },
    methods: {
        over() {
            this.$refs.typewriter[this.$refs.typewriter.length -1].overtext()
        },
        getQuestion(data) {
            // console.log('对话记录', data)
            this.dialogueList = []
            this.dialogueList = data;
        },
        anwserOver() {
            this.$emit("anwserOver");
        }
    }
}
</script>

<style>
.dialogue_left,
.dialogue_right {
    width: 100%;
    display: flex;

}

.dialogue_right {
    justify-content: end;
    margin: 1.6rem 0;
}

.dialogue_left img,
.dialogue_right img {
    width: 3rem;
    height: 3rem;
    margin-right: 1.2rem;
}

.dialogue_right img {
    margin-right: 0rem;
    margin-left: 1.2rem;
}

.dialogue_left1,
.dialogue_right1 {
    /* max-width: 92.4%; */
    background: #FFFFFF;
    font-size: 1rem;
    padding: 0.5rem 0.8rem;
    border-radius: 0 0.5rem 0.5rem 0.5rem;
}

.dialogue_right1 {
    background: #409EFF;
    color: #FFFFFF;
    border-radius: 0.5rem 0 0.5rem 0.5rem;
}

.dialogue_left2 {
    margin-top: 1rem;
    border-top: 1px solid #dddddd;
    padding-top: 0.8rem;
    color: #666666;
}

.dialogue_left3 {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
}
</style>