<template>
    <div id="card-box">
        <div class="title">{{content.title}}</div>
        <div class="card-box1">
            <el-input v-model="input" :type="inputType" :autosize="{minRows: 3}" :placeholder="content.placeholder"
            :maxlength="maxNum == 0 ? '' : maxNum" show-word-limit @input="getContent"
            :disabled="disabled"></el-input>
            <img v-if="inputType != 'number'" @click="clear" src="@/assets/image/card_del.png" alt="">
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        // 标题，提示语
        content: {
            type: Object,
            default: {},
        },
        // 最大字数限制
        maxNum: {
            type: Number,
            default: 0,
        },
        //是否禁用
        disabled: {
            type: Boolean,
            default: false,
        },
        //输入框内容
        inputType:{
            type:String,
            default: 'textarea',
        }
    },
    data() {
        return {
            input: '',
        };
    },
    methods: {
        getContent() {
            this.$emit("getContent", this.input);
        },
        clear(){
            this.input = ''
        }
    }
}
</script>

<style>
#card-box {
    width: 26rem;
    margin: 0 auto;
    margin-top: 1rem;
}

#card-box .title {
    font-size: 1.1rem;
    font-weight: 600;
    color: #111111;
    padding: 0.5rem 0;
    text-align: start;
}

#card-box .el-textarea__inner {
    padding: 1rem 0.8rem;
    font-size: 1.1rem !important;
    padding-bottom: 2rem !important;
    padding-right: 0.8rem !important;
    background-color: #FAFAFA;
    color: #111111;
}
#card-box .el-input__inner{
    font-size: 1rem !important;
    background-color: #FAFAFA;
    color: #111111;
}

#card-box .el-textarea__inner::-webkit-scrollbar {
    display: none;
}
#card-box .el-input__count{
    background-color: #FAFAFA;
}
.card-box1{
    position: relative;
}
.card-box1 img{
    position: absolute;
    bottom: 0.2rem;
    left: 0.8rem;
    width: 1.2rem;
}
</style>