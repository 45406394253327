<template>
    <div id="card-box">
        <div class="title">{{ content.title }}</div>
        <div class="assembly_lable">
            <div :class="lableIndex == i ? 'assembly_box assembly_ubox' : 'assembly_box'" v-for="(item, i) in content.list"
                :key="i" @click="getLable(i)">
                {{ item }}
            </div>
        </div>
        <el-input v-if="strength" type="text" :placeholder="placeholder" v-model="style" maxlength="20" show-word-limit @input="input"
            @change="change" :disabled="disabled"></el-input>

        <div class="slider-box" v-else>
            <div class="slider">
                <el-slider v-model="num" :min="1" :max="5" :step="1" @change='getStrength' :disabled="disabled">
                </el-slider>
            </div>
            <div class="slider-box-num">{{ num }}</div>
        </div>
    </div>
</template>

<script>

export default {
    components: {

    },
    props: {
        content: {
            type: Object,
            default: {},
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        strength:{
            type: Boolean,
            default: true,
        }
    },
    created(){
        this.$emit("getType", this.content.list[0]);
        if(!this.strength){
            this.$emit("strength", 1);
        }
    },
    data() {
        return {
            lableIndex: 0, //选中下标
            placeholder: '请输入自定义' + this.content.title,
            style: '',
            num: 1
        };
    },
    methods: {
        getLable(i) {
            if (this.disabled) {
                return
            }
            this.lableIndex = i
            this.$emit("getType", this.content.list[i]);
        },
        input(value) {
            if (value !== "") {
                this.lableIndex = -1;
            } else {
                this.lableIndex = 0;
                this.$emit("getType", this.content.list[0]);
            }
        },
        change() {
            this.$emit("getType", this.style);
        },
        getStrength(e) {
            this.$emit("strength", e);
        }
    }
}
</script>

<style>
.assembly_lable {
    display: flex;
    flex-wrap: wrap;
}

.assembly_box {
    max-width: 12rem;
    text-align: center;
    font-size: 1rem;
    padding: 0.8rem 1.2rem;
    border-radius: 0.5rem;
    color: #222222;
    margin-bottom: 0.8rem;
    margin-right: 0.8rem;
    background: #FAFAFA;
    border: 1px solid #EEEEEE;
    cursor: pointer;
}

.assembly_ubox {
    color: #ffffff;
    background: #409EFF;
    border: 1px solid #92C8FF;
}

.el-slider__bar {
    background: linear-gradient(to right, #F1F7FF, #409EFF) !important;
}

.el-slider__button {
    border: 2px solid #409EFF;
}
#card-box .el-input__count-inner{
    background-color: #FAFAFA;
}
.slider-box {
    padding: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.slider {
    width: 20rem;
}
.slider-box-num{
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    color: #ffffff;
    font-size: 1.2rem;
    background-color: #409EFF;
}
</style>