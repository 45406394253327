<template>
    <div>
      <div class="talk_text talk_text1" v-if="text.length == 0">
        思考中
        <div class="container">
          <div class="point"></div>
          <div class="point"></div>
          <div class="point"></div>
        </div>
      </div>
      <div :style="{fontSize:size + 'rem'}" v-html="text">
      </div>
    </div>
  </template>
  
  <script>
  import { EventBus } from '@/utils/eventBus.js';
  export default {
    props: {
      content: {
        type: String,
        default: "",
      },
      size:{
        type: String,
        default: "1",
      }
    },
    data() {
      return {
        text: "", //显示的最终文本
        contentStr: "",
        // 定时器ID
        interval: null,
        end: false
      };
    },
    created() {
        // console.log(this.content)
    },
    watch: {
      content(newVal, oldVal) {
        if (newVal !== oldVal) {
          if (newVal == '') {
            this.text = ''
            this.contentStr = ''
            this.end = false
            this.animateText();
          }
          this.contentStr = newVal
        }
      },
    },
    mounted() {
      // 开启文字逐个显示定时器
      this.animateText();
      EventBus.$on('stopAnimateText', () => {
        this.stopAnimateText()
      })
    },
    beforeDestroy() {
      // 组件注销  定时器清除
      clearInterval(this.interval);
      EventBus.$off('stopAnimateText');
    },
    methods: {
      overtext() {
        this.end = true
      },
      animateText() {
        // this.contentStr = this.content;
        // 用来储存当前读取到第几个字符了
        let counter = 0;
        this.text = "";
        // 使用setInterval来逐字显示文本
        this.interval = setInterval(() => {
          if (counter < this.contentStr.length) {
            if (this.contentStr !== "") {
              if (counter < this.contentStr.length) {
                // 获取当前需要展示的文字
                const showText = this.contentStr[counter];
                // 凭借字符串
                this.text += showText;
                counter += 1;
              }
              // console.log('1111---请求结束',counter ,this.contentStr.length ,this.end)
              if (counter == this.contentStr.length && this.end) {
                this.$emit("anwserOver");
                clearInterval(this.interval);
              }
            }
          }else if(counter == this.contentStr.length && this.end){
            this.$emit("anwserOver");
            clearInterval(this.interval);
          }
        }, 30); // 每50毫秒添加一个字符
      },
      // 停止打字
      stopAnimateText() {
        clearInterval(this.interval);
        this.interval = null;
      }
    },
  };
  </script>
  
  <style>
  .talk_text1 {
    display: flex;
    align-items: center;
    margin-right: 10px;
  }
  
  .container {
    display: flex;
    margin-left: 10px;
  }
  
  .container .point {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background: #fff;
    margin: 0 0.3rem;
    user-select: none;
    position: relative;
  }
  
  .container .point::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: wave 2.2s ease-out infinite;
  }
  
  @keyframes wave {
  
    50%,
    70% {
      transform: scale(2.0);
    }
  
    80%,
    100% {
      opacity: 0;
    }
  }
  
  .container .point:nth-child(1) {
    background: #7ef9ff;
  }
  
  .container .point:nth-child(2) {
    background: #89cff0;
  }
  
  .container .point:nth-child(3) {
    background: #4682b4;
  }
  
  .container .point:nth-child(1)::before {
    animation-delay: 0s;
  }
  
  .container .point:nth-child(2)::before {
    animation-delay: 0.2s;
  }
  
  .container .point:nth-child(3)::before {
    animation-delay: 0.4s;
  }
  
  .talk_text {
    /* font-size: 1rem; */
  }
  </style>