<template>
    <div class="vip_benefit">
        <div class="vip_benefit1">
            <img src="../assets/image/vip_bg5.png" alt="">

            <div class="vip_benefit2">
                <div class="vip_benefit3" v-for="(item,i) in vipBenefit" :key="i">
                    <img :src="item.icon" alt="">
                    {{item.name}}
                </div>
            </div>
        </div>

        <div class="vip_benefit4">
            <div class="vip_benefit5">会员说明</div>
            <div class="vip_benefit6" v-for="(item,i) in vipNotice" :key="i">
                {{ item }}
            </div>
        </div>
    </div>
</template>

<script>
import data from '@/assets/data';
export default {
    data() {
        return {
            vipBenefit:data.data.vipBenefit,
            vipNotice:data.data.vipNotice
        }
    },
    methods: {

    }
}

</script>

<style>
.vip_benefit1 {
    width: 65rem;
    height: 37rem;
    background: url('../assets/image/vip_bg4.png') no-repeat;
    background-size: 100% 100%;
    margin-top: 2rem;
    padding-top: 17rem;
    display: flex;
}

.vip_benefit1 img {
    width: 16rem;
    height: 16rem;
    padding-left: 5rem;
}

.vip_benefit2 {
    width: 30rem;
    height: 15rem;
    margin-left: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.vip_benefit3 {
    display: flex;
    flex-flow: column;
    font-size: 1rem;
    width: 7rem;
    align-items: center;
}

.vip_benefit3 img {
    width: 4rem;
    height: 4rem;
    padding-left: 0;
    margin-bottom: 0.8rem;
}
.vip_benefit4{
    width: 65rem;
    padding: 2.5rem;
    border: 1px solid #EEB767;
    border-radius: 1rem;
    margin-top: 2rem;
}
.vip_benefit5{
    font-size: 2.2rem;
    text-align: center;
    color: #795621;
    font-weight: 700;
}
.vip_benefit6{
    color: #333333;
    font-size: 1rem;
    text-align: center;
    margin-top: 0.5rem;
}
</style>