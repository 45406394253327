<template>
  <div id="talk">
    <top index="1" @login="login"></top>
    <div class="talk_content">
      <talkhistory @hisDetail="hisDetail" @newdialogue="newTalk"></talkhistory>
      <div class="talk_dialogue">
        <!-- 提示问题 -->
        <tips v-if="tipsShow" @putQuestion="askQuestion"></tips>
        <!-- 对话 -->
        <div v-else class="talk_dialogue1" ref="chatContainer">
          <dialogue ref="dialogue" :head="headicon" :max="88" @anwserOver="anwserOver"></dialogue>
        </div>
        <!-- 键盘 -->
        <div class="keyboard_bottom1">
          <keyboard ref="keyboard" @login="login" @putQuestion="askQuestion" :nextQuestion="nextanwser" @stopTalk="stopTalk">
          </keyboard>
          <div class="bottom_number">
            <a href="https://beian.miit.gov.cn" > 鄂ICP备2023031487号 </a>
            <div>Copyright © 2023 - 2025 hbsry.com 版权所有</div>
          </div>
        </div>
      </div>
    </div>
    <login ref="login"></login>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import talkhistory from '@/components/talk_history.vue'
import dialogue from '@/components/dialogue.vue';
import tips from '@/components/tips.vue'
import keyboard from '@/components/keyboard.vue'
import login from '@/components/login.vue';
import api from "@/api/api";
import gzhpopup from '@/components/gzhpopup.vue'
export default {
  components: {
    top, talkhistory, dialogue, tips, keyboard, login,gzhpopup
  },
  data() {
    return {
      tipsShow: true, //提示内容显示
      talkList: [], //ai对话内容
      sessionId: 0, //会话id
      headicon: require('@/assets/image/Aitalk.png'), //头像
      nextanwser: false, //下一个问题
      stopTalkid: 0, // 停止对话id
      intervalId: null, // 定时器ID
      tmp: null,
    }
  },
  created() {
    this.newTalk();
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 创建新对话
    newTalk(i) {
      if (i == "new") {
        this.tipsShow = true
        this.talkList = [];
        if (!this.tipsShow) {
          setTimeout(() => {
            this.$refs.dialogue.getQuestion(this.talkList);
          }, 10)
        }
      }
      api.createSession().then((res) => {
        if (res.code == 200) {
          if (i == "new") {
            this.$message({
              message: "新对话已创建",
              type: "success",
            });
          }
          this.sessionId = res.data;
        }
      });
    },
    // 查询对话详情
    hisDetail(data) {
      this.tipsShow = false
      this.pageNum = 1;
      this.talkList = [];
      this.sessionId = data.id
      this.headicon = data.icon
      api.contentSession({
        sessionId: data.id,
        pageNum: this.pageNum,
        pageSize: 50,
      }).then((res) => {
        // console.log(res)
        if (res.code == 200) {
          for (let item of res.data) {
            item.anwser = item.anwser.replace(/\n/g, "<br/>");
          }
          this.talkList = this.talkList.concat(res.data.reverse());
        }
        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
          this.scrollToBottom();
        }, 10)
      })

    },

    // 提问
    askQuestion(item) {
      if(this.sessionId == 0){
        this.$refs.login.show()
        return
      }
      this.nextanwser = true;
      this.tipsShow = false
      let arry = {
        question: item,
        sessionId: this.sessionId,
        anwser: "",
      };
      this.talkList.push(arry);
      this.getAnwser(arry);
      setTimeout(() => {
        this.$refs.dialogue.getQuestion(this.talkList);
      }, 10)
      this.intervalId = setInterval(() => {
        // console.log('111',this.intervalId)
        this.scrollToBottom();
      }, 500)

    },
    // 流式请求获取回答
    async getAnwser(data) {
      // // fetch请求
      const token = localStorage.getItem("token_key");
      let headers = {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: token,
      };
      if (token == null) {
        headers = {
          "Content-Type": "application/x-www-form-urlencoded",
        };
      }
      data.platform = 2
      const options = {
        method: "POST", // 请求方法为POST
        body: new URLSearchParams(data).toString(), // 将可读流作为请求体
        // 其他选项，如headers等（如果需要）
        headers: headers,
        // 指定响应类型为stream
        responseType: 'stream',
      };
      // 使用fetch API发起请求，指定响应类型为response以获取ReadableStream对象
      const response = await fetch(
        process.env.VUE_APP_BASE_API + "assistant/app/session/question/stream",
        options
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      // 获取可读流读取器对象 循环
      const reader = response.body.getReader();
      this.$refs.keyboard.degree()
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        const decoder = new TextDecoder();
        const txt = decoder.decode(value);
        const t = this.getStr(txt)
        console.log('reader-----txt', t)
        if (t == "登录状态已过期") {
          this.$refs.login.show()
          return
        }
        this.talkList[this.talkList.length - 1].anwser =
          this.talkList[this.talkList.length - 1].anwser + t;
        setTimeout(() => {
          this.$refs.dialogue.getQuestion(this.talkList);
        }, 10)
      }
      this.$refs.dialogue.over()
    },
    // 返回值拼接
    getStr(str) {
      if (this.tmp) {
        str = this.tmp + str
      }
      let arr = str.split("\n\n");
      let res = "";
      for (let s of arr) {
        if (!s || s == "") {
          continue;
        }
        try {
          let obj = JSON.parse(s);
          const code = obj.code;
          if ("401" == code) {
            return "";
          } else if ("700" == code) {
            break;
          } else if ("200" != code) {
            return obj.msg;
          } else {
            const data = obj.data.replace(/\n/g, "<br/>");
            res += data;
            this.stopTalkid = obj.id
          }
          this.tmp = null;
        } catch (err) {
          console.log(err);
          if (this.tmp) {
            this.tmp = null
          } else {
            this.tmp = str
          }
        }
      }
      return res;
    },
    scrollToBottom() {
      // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
      this.$nextTick(() => {
        const chatContainer = this.$refs.chatContainer;
        chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
      });
    },
    anwserOver() {
      // 问题回答完毕
      this.nextanwser = false;
      // console.log('222',this.intervalId)
      clearInterval(this.intervalId)
    },
    //停止对话
    stopTalk() {
      api.stopquestion(this.stopTalkid).then((res) => {
        if (res.code == 200) {
          clearInterval(this.intervalId)
        }
      })
    }
  }
}
</script>

<style>
#talk {
  width: 128rem;
  height: 100vh;
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.talk_content {
  width: 128rem;
  height: 90vh;
  /* background-color: aqua; */
  padding: 0;
  margin-top: 1vh;
  display: flex;

}

.talk_dialogue {
  width: 114rem;
  height: 90vh;
  position: relative;
}

.talk_dialogue1 {
  width: 70rem;
  height: 70vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
  margin: 0 auto;
}

.keyboard_bottom1 {
  width: 70rem;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.bottom_number {
  width: 70rem;
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 0.8rem;
  margin-top: 1vh;
}
</style>