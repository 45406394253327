<template>
    <div class="createContent2">
        <cardImg ref="upImage"></cardImg>
        <cardGrade @getGrade="getGrade"></cardGrade>
    </div>
</template>

<script>
import cardGrade from '../card_grade.vue';
import cardImg from '../card_img.vue';
export default {
    components: {
        cardImg, cardGrade
    },
    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    created() {

    },
    data() {
        return {
            grade: {},

        }
    },
    methods: {
        // 选择年级
        getGrade(item) {
            this.grade = item
        },
        // 提交
        upImage(){
            // console.log('111',this.grade)
            this.$refs.upImage.submitUpload(this.grade)
        }
    }
}
</script>

<style>

</style>