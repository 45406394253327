<template>
  <div id="vip">
    <top index="3" @login="login"></top>
    <div class="vip_content">
      <div class="vip_content1">
        <div class="vip_content2">请选择你想要的会员方案</div>

        <!-- 会员价格 -->
        <vipPrice @login="login"></vipPrice>

        <!-- 会员权益 -->
        <vipBenefit></vipBenefit>

        <div class="bottom_number">
          <a href="https://beian.miit.gov.cn"> 鄂ICP备2023031487号 </a>
          <div>Copyright © 2023 - 2025 hbsry.com 版权所有</div>
        </div>
      </div>
    </div>
    <vipBottom></vipBottom>
    <login ref="login"></login>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import vipBottom from '@/components/vip_bottom.vue';
import vipPrice from '@/components/vip_price.vue';
import vipBenefit from '@/components/vip_benefit.vue'
import login from '@/components/login.vue';
import gzhpopup from '@/components/gzhpopup.vue'
export default {
  components: {
    top, vipBottom, vipPrice, vipBenefit, login,gzhpopup
  },
  methods: {
    login() {
      this.$refs.login.show()
    }
  }
}
</script>

<style>
#vip {
  width: 128rem;
  height: 100vh;
  background: url('../assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.vip_content {
  width: 128rem;
  height: 90vh;
  overflow: auto;
  -ms-overflow-style: none;
  /* IE和Edge浏览器下隐藏滚动条 */
  scrollbar-width: none;
  /* Firefox下隐藏滚动条 */
}

.vip_content1 {
  width: 65rem;
  margin: 0 auto;
  padding-top: 1.5rem;
  margin-bottom: 5rem;
}

.vip_content2 {
  width: 65rem;
  font-size: 2rem;
  color: #000000;
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
}

.bottom_number {
  width: 70rem;
  text-align: center;
  font-weight: 600;
  color: #000000;
  font-size: 0.8rem;
  margin-top: 2rem;
}
</style>