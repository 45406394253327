<template>
    <div id="card-box">
        <div class="title">{{ title }}</div>
        <div class="card_img">
            <div class="card_img2" v-for='(value, key) in imgs'>
                <div class="card_img3">
                    <img @click="bigImg(key)" src="../assets/image/card_img1.png" alt="">
                    <img @click="delImg(key)" src="../assets/image/card_img2.png" alt="">
                </div>
                <img :src="getObjectURL(value)" object-fit="fill" alt="">
            </div>
            <div class="card_img1" v-if="imgLen >= 5 ? false : true">
                <div class="card_img1_1">
                    <img src="../assets/image/up_img.png" alt="">
                    <div>图片上传</div>
                </div>
                <input type="file" class="upload" @change="addImg" ref="inputer" multiple accept="image/*" />
            </div>
        </div>

        <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="">
        </el-dialog>
    </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
import axios from "axios";
export default {
    props: {
        // 标题
        title: {
            type: String,
            default: '上传图片',
        },
    },
    data() {
        return {
            url: process.env.VUE_APP_BASE_API + "assistant/app/question/zwpg", //上传地址
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': localStorage.getItem('token_key')
            }, //请求头
            formData: new FormData(), //FormData参数
            imgs: {}, // 图片数组
            imgLen: 0, //图片数
            dialogImageUrl:'',
            dialogVisible:false,

        }
    },
    methods: {
        // 上传
        submitUpload(item) {
            if (this.imgLen == 0) {
                this.$message({
                    message: '至少上传一张图片',
                    type: "warning",
                });
                return
            }
            EventBus.$emit('loading', true);
            for (let key in this.imgs) {
                let name = key.split('?')[0];
                this.formData.append('files', this.imgs[key], name);
            }
            this.formData.append('grade', item.grade);
            axios.post(this.url, this.formData, {
                headers: this.headers
            })
                .then(response => {
                    let data = response.data
                    EventBus.$emit('loading', false);
                    if (data.code == 200) {
                        EventBus.$emit('correct', data.data);
                    } else {
                        this.$message({
                            message: '作文批改失败！',
                            type: "warning",
                        });
                    }
                    // console.log('图片上传成功', data);
                })

        },
        addImg(event) {
            let inputDOM = this.$refs.inputer;
            // 通过DOM取文件数据
            this.fil = inputDOM.files;
            let oldLen = this.imgLen;
            let len = this.fil.length + oldLen;
            if (len > 5) {
                this.$message({
                    message: '最多可上传5张，您还可以上传' + (5 - oldLen) + '张',
                    type: "warning",
                });
                return false;
            }
            for (let i = 0; i < this.fil.length; i++) {
                // let size = Math.floor(this.fil[i].size / 1024);
                // if (size > 5 * 1024 * 1024) {
                //     alert('请选择5M以内的图片！');
                //     return false
                // }
                this.imgLen++;
                this.$set(this.imgs, this.fil[i].name + '?' + new Date().getTime() + i, this.fil[i]);
            }
        },
        getObjectURL(file) {
            var url = null;
            if (window.createObjectURL != undefined) { // basic
                url = window.createObjectURL(file);
            } else if (window.URL != undefined) { // mozilla(firefox)
                url = window.URL.createObjectURL(file);
            } else if (window.webkitURL != undefined) { // webkit or chrome
                url = window.webkitURL.createObjectURL(file);
            }
            return url;
        },
        delImg(key) {
            this.$delete(this.imgs, key);
            this.imgLen--;
        },
        bigImg(key) {
            this.dialogVisible = true
            this.dialogImageUrl = this.getObjectURL(this.imgs[key])
        }
    }
}
</script>

<style>
.card_img {
    width: 26rem;
    display: flex;
    /* background-color: aqua; */
}

.card_img1 {
    width: 5rem;
    height: 6.1rem;
    font-size: 0.9rem;
    text-align: center;
    background-color: aliceblue;
    position: relative;
    margin-right: 0.2rem;
    overflow: hidden;
}

.card_img1 .upload {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    opacity: 0;
}

.card_img1_1 {
    width: 5rem;
    height: 6.1rem;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.card_img1 img {
    width: 3rem;
    margin-bottom: 0.3rem;
}

.card_img2 {
    width: 5rem;
    height: 6.1rem;
    margin-right: 0.3rem;
    position: relative;
}

.card_img2 img {
    width: 100%;
    height: 100%;
}

.card_img3 {
    width: 5rem;
    padding: 0 0.8rem;
    height: 100%;
    color: #FFFFFF;
    position: absolute;
    opacity: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

}

.card_img3 img {
    width: 1.5rem;
    height: 1.5rem;
}


.card_img3:hover {
    background-color: rgba(17, 17, 17, 0.6);
    opacity: 1;
}
</style>