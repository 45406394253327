<template>
    <div v-if="polishingShow" class="polishing_popup" @click="polishingShow = false">
        <div class="polishing_popup1" @click.stop="polishingShow = true">
            <div class="polishing_popup2">
                <div class="polishing_popup3">改写润色</div>
                <img @click="polishingShow = false" src="../assets/image/close.png" alt="">
                <div class="polishing_popup4"  ref="chatContainer">
                    <Typewriter ref="typewriter" size="1.4" :content="anwser" @anwserOver="anwserOver"></Typewriter>
                </div>
            </div>
            <createBottom @bottomBTn="bottomBTn"></createBottom>
        </div>
    </div>
</template>

<script>
import Typewriter from './typewriter.vue';
import createBottom from './create_bottom.vue'
import { saveAs } from 'file-saver';
export default {
    components: {
        createBottom,Typewriter
    },
    data() {
        return {
            polishingShow: false, //一键润色弹框
            polishfinish:false, //润色完成
            anwser:'',
            url: process.env.VUE_APP_BASE_API + "assistant/app/question/stream/zwrs", //一键润色
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Authorization': localStorage.getItem('token_key')
            }, //请求头
        }
    },
    methods: {
        show(id) {
            this.polishingShow = true
            this.polishing(id)
        },
         // 一键润色
         async polishing(id) {
            let response = await fetch(this.url, {
                method: 'POST',
                body: `id=${id}`,
                headers: this.headers
            })
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            // 获取可读流读取器对象 循环
            const reader = response.body.getReader();
            this.polishfinish = false
            while (true) {
                const { done, value } = await reader.read();
                if (done) break;
                const decoder = new TextDecoder();
                const txt = decoder.decode(value);
                const t = this.getStr(txt)
                if (t && t != '') {
                    this.anwser = this.anwser + t;
                    //   console.log(this.anwser)
                }
                if (this.polishingShow) {
                    this.scrollToBottom()
                }
            }
            this.$refs.typewriter.overtext()
        },
        // 返回值拼接
        getStr(str) {
            // console.log('str',str)
            if (this.tmp) {
                str = this.tmp + str;
            }
            let arr = str.split("\n\n");

            let res = "";
            for (let s of arr) {
                if (!s || s == "") {
                    continue;
                }
                try {
                    let obj = JSON.parse(s);
                    const code = obj.code;
                    if ("401" == code) {
                        return "";
                    } else if ("700" == code) {
                        break;
                    } else if ("200" != code) {
                        return obj.msg;
                    } else {
                        const data = obj.data.replace(/\n/g, "<br/>");
                        res += data;
                    }
                    this.tmp = null;
                } catch (err) {
                    console.log(err);
                    if (this.tmp) {
                        this.tmp = null
                    } else {
                        this.tmp = str
                    }
                }
            }
            return res;
        },
        anwserOver(){
            this.polishfinish = true
        },
        bottomBTn(i) {
            // console.log(i)
            if (!this.polishfinish) {
                this.$message.warning('请等待润色完成！');
                return
            }
            this.anwser = this.anwser.replace(/<br\s*\/?>/g, '\n');
            if (i == 0) {
                let strData = new Blob([this.anwser], { type: 'text/plain;charset=utf-8' });
                saveAs(strData, `作文批改.txt`);
            } else if (i == 1) {
                this.$copyText(this.anwser).then(() => {
                    this.$message.success('文本已复制');
                }).catch(() => {
                    this.$message.warning('复制失败');
                })
            } else if (i == 2) {
                this.$message({
                    message: "分享",
                    type: "success",
                });
            }
        },
        scrollToBottom() {
            // 使用Vue的$nextTick确保DOM更新完成后再进行滚动操作
            this.$nextTick(() => {
                const chatContainer = this.$refs.chatContainer;
                chatContainer.scrollTop = chatContainer.scrollHeight; // 滚动到底部
            });
        },
    }

}
</script>
<style>
.polishing_popup {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    z-index: 1000;
}

.polishing_popup1 {
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translate(-50%);
    width: 70%;
    height: 90vh;
    background-color: #FFFFFF;
    border-radius: 1rem 1rem 0 0;
}

.polishing_popup2 {
    height: 82vh;
    width: 100%;
    padding-top: 2vh;
}

.polishing_popup3 {
    font-size: 1.6rem;
    color: #000000;
    text-align: center;
    font-weight: bold;
}

.polishing_popup2 img {
    width: 2rem;
    position: absolute;
    right: 2rem;
    top: 2vh;
}

.polishing_popup4 {
    width: 100%;
    height: 75vh;
    padding: 1em 3rem;
    font-size: 1.33rem;
    color: #333333;
    overflow: auto;
    -ms-overflow-style: none;
    /* IE和Edge浏览器下隐藏滚动条 */
    scrollbar-width: none;
    /* Firefox下隐藏滚动条 */
    /* background-color: aqua; */
}
</style>