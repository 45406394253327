
let data = {
  lable:[''],
  // 登录选择
  otherList: [{
    name: '验证码登录',
    icon: require('@/assets/image/login1.png')
  },
  {
    name: '微信登录',
    icon: require('@/assets/image/login2.png')
  }],
  //左边导航栏信息
  homeLift: [{
    name: 'AI写作',
    icon: require('@/assets/image/home_left1.png')
  }, {
    name: 'AI对话',
    icon: require('@/assets/image/home_left2.png')
  }, {
    name: 'AI顾问',
    icon: require('@/assets/image/home_left3.png')
  }, {
    name: '我的创作',
    icon: require('@/assets/image/home_left5.png')
  }, {
    name: '更多',
    icon: require('@/assets/image/home_left4.png')
  }, {
    name: '',
    icon: require('@/assets/image/home_left6.png')
  }],

  // 支付图标
  payImg: [
    require('@/assets/image/vip_icon3.png'),
    require('@/assets/image/vip_icon2.png'),
    require('@/assets/image/vip_icon4.png'),
    require('@/assets/image/vip_icon5.png'),
  ],

  // 会员权益
  vipBenefit: [{
    name: '解锁全部功能',
    icon: require('@/assets/image/vip_icon6.png')
  }, {
    name: '不限次数',
    icon: require('@/assets/image/vip_icon7.png')
  }, {
    name: '玩转AI写作',
    icon: require('@/assets/image/vip_icon8.png')
  }, {
    name: '客服专属',
    icon: require('@/assets/image/vip_icon9.png')
  }, {
    name: '超低延迟',
    icon: require('@/assets/image/vip_icon10.png')
  }, {
    name: '永久保存',
    icon: require('@/assets/image/vip_icon11.png')
  }, {
    name: '解锁会员专区',
    icon: require('@/assets/image/vip_icon12.png')
  }, {
    name: '更优质结果',
    icon: require('@/assets/image/vip_icon13.png')
  }],

  // 会员须知
  vipNotice: [
    '请先试用满意后开通，会员开通成功后概不退款，',
    '会员价格会随着功能的增多会逐步涨价，现在开通最划算',
    '有任何开通会员问题可以联系客服',
    '时长一个月=30天，会员时长到期后不会自动续费',
    '会员享受三端互通，即APP、PC端、小程序共享同一个会员账号'
  ],
  // 创作图标
  createImg: [
    require('@/assets/image/create_icon1.png'),
    require('@/assets/image/create_icon2.png'),
    require('@/assets/image/create_icon3.png'),
    require('@/assets/image/create_icon4.png'),
  ],
  // 创作页面btn
  resultBtn: [{
    icon: require('@/assets/image/create_icon6.png'),
    name: '保存'
  },
  {
    icon: require('@/assets/image/create_icon7.png'),
    name: '复制'
  },
  // {
  //   icon: require('@/assets/image/create_icon8.png'),
  //   name: '分享'
  // }
],
  // 历史记录列表
  historyBtn: [
    require('@/assets/image/history_icon1.png'),
    require('@/assets/image/history_icon3.png'),
    require('@/assets/image/login3.png'),
    require('@/assets/image/login4.png'),
    require('@/assets/image/card_del.png'),],

  // 设置
  set: [{
    img: require('@/assets/image/set2.png'),
    text: '用户协议'
  }, {
    img: require('@/assets/image/set3.png'),
    text: '隐私政策'
  }, {
    img: require('@/assets/image/set4.png'),
    text: '退出登录'
  }],
  //vip权益
  vipEquity: [{
    img: require('@/assets/image/userInfo1.png'),
    text: '解锁全部功能'
  },
  {
    img: require('@/assets/image/userInfo2.png'),
    text: '不限次数'
  }, {
    img: require('@/assets/image/userInfo3.png'),
    text: '玩转Ai写作'
  }, {
    img: require('@/assets/image/userInfo4.png'),
    text: '客服专属'
  }, {
    img: require('@/assets/image/userInfo5.png'),
    text: '超低延迟'
  }, {
    img: require('@/assets/image/userInfo6.png'),
    text: '永久保存'
  }, {
    img: require('@/assets/image/userInfo7.png'),
    text: '支持续写功能'
  }, {
    img: require('@/assets/image/userInfo8.png'),
    text: '更优质结果'
  }],

  homeHot: [
    {
      title: '热门创作',
      icon: require('@/assets/image/lable.png'),
      name: 'Ai写文章',
      description: '一键智能文章写作',
      type: 'xwz',
      id: 100,
    },
    {
      title: '热门创作',
      icon: require('@/assets/image/lable2.png'),
      name: '写新闻稿',
      description: '智能撰写新闻稿',
      type: 'xxwg',
      id: 101,
    },
    {
      title: '热门创作',
      icon: require('@/assets/image/lable1.png'),
      name: '原创改写',
      description: '一键伪原创',
      type: 'ycgx',
      id: 102,
    }
  ],
  score: [
    {
      title: '主题突出',
      fraction: 0,
      type: 'subject'
    },
    {
      title: '引用恰当',
      fraction: 0,
      type: 'appropriate'
    },
    {
      title: '理论深度',
      fraction: 0,
      type: 'theory'
    },
    {
      title: '结构清晰',
      fraction: 0,
      type: 'structure'
    },
    {
      title: '修辞技巧',
      fraction: 0,
      type: 'rhetoric'
    },
    {
      title: '情感共鸣',
      fraction: 0,
      type: 'emotion'
    },
    {
      title: '论证逻辑',
      fraction: 0,
      type: 'logic'
    },
    {
      title: '实例丰富',
      fraction: 0,
      type: 'rich'
    },
    {
      title: '语言表达',
      fraction: 0,
      type: 'expression'
    },
    {
      title: '观点新颖',
      fraction: 0,
      type: 'viewpoint'
    }
  ],
  evaluate: [{
    title: '总结',
    content: ''
  },
  {
    title: '优点',
    content: ''
  },
  {
    title: '建议',
    content: ''
  }],

  adviseBg:[
    require('@/assets/image/adviser_bg.png'),
    require('@/assets/image/adviser_bg1.png'),
    require('@/assets/image/adviser_bg2.png'),
    require('@/assets/image/adviser_bg3.png'),
  ],
  ewm: 'Ci5pbXBvd2VyQm94IC50aXRsZSB7CiAgICBkaXNwbGF5OiBub25lOwp9Ci5pbXBvd2VyQm94IC5xcmNvZGUubGlnaHRCb3JkZXIgewogICAgd2lkdGg6ICcxMDAlJzsKfQouaW1wb3dlckJveCAuaW5mbyB7CiAgICBkaXNwbGF5OiBub25lOwp9'
}


module.exports = {
  data: data
}