<template>
    <div id="loading">
        <div class="loading">
            <div class="loading1"></div>

            <div class="loading2">
                <div class="loading3">
                    <div class="loading4">{{ `${num}%` }}</div>
                </div>
            </div>
            <div class="loadText">{{ loadText }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // loadText:{
        //     type:String,
        //     default:'文章生成中，请稍等...'
        // }
    },
    data() {
        return {
            loadText: '文章生成中，请稍等...',
            loadShow: false,
            num: 1,
            setIntervalId: null,
        }
    },
    beforeDestroy() {
        if (this.setIntervalId) {
            clearInterval(this.setIntervalId);
        }
    },
    created() {
        this.startTimer()
    },
    methods: {
        startTimer() {
            this.setIntervalId = setInterval(() => {
                this.num++
                console.log(this.num)
                if (this.num == 100) {
                    this.num = 1
                }
            }, 1000);
        }
    }
}
</script>

<style>
#loading {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00000038;
    z-index: 1000;
}

.loading {
    width: 18rem;
    height: 18rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    /* background-color: aqua; */
    display: flex;
    flex-flow: column;
    align-items: center;
    border-radius: 1rem;
    background: linear-gradient(90deg, #EFF5F8 0%, #9BD6E7 100%);

    /* justify-content: center; */
}

.loading1 {
    position: absolute;
    top: 2rem;
    border: 1rem solid #85CEE7;
    border-radius: 50%;
    border-top: 1rem solid #027FFF;
    width: 12rem;
    height: 12rem;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loading2 {
    width: 12rem;
    height: 12rem;
    border: 1rem solid #85CEE7;
    border-radius: 50%;
    margin-top: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading3 {
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background: linear-gradient(90deg, #D3E9F3 0%, #B7E0ED 100%);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.loading4 {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    background-color: #FBFEFF;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.1rem;
    color: #18B9C9;
    font-weight: bold;
}

.loadText {
    font-size: 1.2rem;
    color: #4792AE;
    margin-top: 0.5rem;
}
</style>