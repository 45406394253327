<template>
    <div id="card-box">
        <div class="title">{{ title }}</div>
        <div class="card-txt">
            <input type="file" class="upload" @change="addImg" ref="inputer" multiple :disabled="disabled"
                accept=".txt,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            <img src="../assets/image/up_txt.png" v-if="txtName == '点击上传文档'" alt="">
            <div class="card-txt1">{{ txtName }}</div>
            <div class="card-txt2" v-if="txtName == '点击上传文档'">支持Word、Excel、PDF、TXT，限制8M</div>
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/utils/eventBus";
export default {
    props: {
        // 标题
        title: {
            type: String,
            default: '上传文档',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            txtName: '点击上传文档',
            fil: {},
            txtLen:0,
        }
    },
    methods: {
        addImg(event) {
            let inputDOM = this.$refs.inputer;
            this.fil = inputDOM.files[0];
            this.txtName = inputDOM.files[0].name
            this.txtLen = inputDOM.files.length
        },
        async submitUpload(question) {
            if(this.txtLen === 0){
                this.$message({
                    message: "请选择文档",
                    type: "warning",
                });
                return
            }
            if (question == '') {
                this.$message({
                    message: "请填写问题！",
                    type: "warning",
                });
                return
            }
            EventBus.$emit('wdljquestion', {
                file: this.fil,
                question: question,
            })
        },
    }
}
</script>

<style>
.card-txt {
    background-color: #FAFAFA;
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    padding: 2rem 0;
    border-radius: 0.5rem;
    cursor: default;
}

.card-txt img {
    width: 4rem;
}

.card-txt1 {
    font-size: 1.2rem;
    color: #666666;
    font-weight: 700;
    margin: 0.5rem 0;
}

.card-txt2 {
    font-size: 1rem;
    color: #999999;
}

.card-txt .upload {
    width: 25rem;
    height: 8rem;
    position: absolute;
    opacity: 0;
    background-color: aqua;
}
</style>