import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/home.vue'
import Talk from '@/pages/talk.vue'
import Adviser from '@/pages/adviser.vue'
import Vip from '@/pages/vip.vue'
import Create from '@/pages/create.vue';
Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/talk',
    name: 'Talk',
    component: Talk,
  },
  {
    path: '/adviser',
    name: 'Adviser',
    component: Adviser,
  },
  {
    path: '/vip',
    name: 'Vip',
    component: Vip,
  },
  {
    path: '/create',
    name: 'Create',
    component: Create,
  }
];

const router = new VueRouter({
  routes
});
// 解决重复点击导航时，控制台出现报错
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}
export default router;
