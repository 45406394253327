<template>
    <div class="top-bar">
        <div class="top-bar-logo" @click="changepage(0)">
            <img src="../assets/logo.png" alt="">
            AI写作助手
        </div>
        <div class="top-bar-lable">
            <div v-for="(item, i) in lable" :class="lableIndex == i ? 'lable_1' : 'lable_1 lable_2'"
                @click="changepage(i)">
                {{ item }}
                <div v-if="lableIndex == i" class="lable-line"></div>
                <div v-if="item == '会员中心'" class="preferential">
                    特惠
                </div>
            </div>
        </div>
        <div class="top-bar-login"
            :style="{ background: login ? '' : backgroundcolor, flexDirection: login ? 'row-reverse' : 'row' }"
            @click="seeUserinfo">
            <el-popover placement="bottom" popper-class="el-userInfo" v-model="visible">
                <img v-show="login" class="top-bar-login_img1" :src="headImg[0]"  slot="reference" alt="">
                <!-- 个人信息 -->
                 <user-info :userInfo="userinfo" @openVip="openVip"></user-info>
            </el-popover>
            <img v-if="!login" class="top-bar-login_img" :src="headImg[1]" alt="">
            <div class="top-bar-login1">{{ login ? userinfo.nickname : '登录/注册' }}</div>
            <div class="top-vip" v-if="login" :style="{ backgroundColor: userinfo.vip ? '#409EFF' : '#B4B4B4' }">{{ userinfo.vip ?
                '已开通' : '未开通' }}</div>
        </div>
    </div>
</template>

<script>
import api from "@/api/api";
import userInfo from "./user_info.vue";
import { EventBus } from '@/utils/eventBus.js';

export default {
    components:{
        userInfo
    },
    props: {
        index: {
            type: String,
            default: ''
        }
    },
    created() {
        this.getUserinfo()
    },
    mounted() {
      EventBus.$on('anwserStart', () => {
        this.ask = true
      })
      EventBus.$on('anwserOver', () => {
        this.ask = false
      })
      
    },
    beforeDestroy() {
      EventBus.$off('anwserStart');
      EventBus.$off('anwserOver');
    },
    data() {
        return {
            backgroundcolor: 'linear-gradient(to right, #879FFF, #B487FF, #FDDBBA)',
            lable: ['AI写作', 'AI对话', 'AI顾问','会员中心'],//顶部选项 ''
            headImg: [require('@/assets/image/head.png'), require('@/assets/image/head1.png')],
            lableIndex: this.index, //顶部选项下标
            login: false,
            userinfo: {}, // 用户信息
            visible: false,
            ask:false,
        }
    },
    methods: {
        // 切换lable
        changepage(i) {
            if(this.ask){
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            this.lableIndex = i
            if (i == 0) {
                this.$router.push({ path: '/' });
            } else if (i == 1) {
                this.$router.push({ path: '/talk' });
            } else if (i == 2) {
                this.$router.push({ path: '/adviser' });
            } else if (i == 3) {
                this.$router.push({ path: '/vip' });
            }
        },
        getUserinfo() {
            api.getUserinfo().then((res) => {
                if (res.code == 200) {
                    this.login = true
                    this.userinfo = res.data
                } else {
                    this.login = false
                }
            })

        },
        seeUserinfo() {
            if (!this.login) {
                this.$emit('login')
            } else {
                this.visible = true
            }
        },
        openVip(){
            if(this.ask){
                this.$message({
                    message: '请等待问题回答完毕，或者手动停止！',
                    type: 'warning'
                });
                return
            }
            this.$router.push({ path: '/vip' });
        }
    }
}
</script>

<style>
.top-bar {
    width: 128rem;
    height: 5rem;
    /* padding: 0 1.2rem; */
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 1000;
}

.top-bar-logo {
    display: flex;
    align-items: center;
    color: #333333;
    font-size: 1.6rem;
    font-weight: 400;
    margin-left: 1.5rem;
    cursor: default;
}

.top-bar-logo img {
    width: 3rem;
    margin-right: 1rem;
}

.top-bar-lable {
    width: 96rem;
    display: flex;
}

.lable_1 {
    font-size: 1.3rem;
    font-weight: 600;
    color: #409EFF;
    position: relative;
    margin-right: 2rem;
    cursor: pointer;
    position: relative;
}

.lable_2 {
    color: #666666;
}

.lable-line {
    width: 80%;
    height: 6px;
    background: linear-gradient(144deg, #409EFF 0%, #A346FA 100%);
    border-radius: 4px;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
}

.preferential {
    position: absolute;
    padding: 3px 8px;
    background-color: #2873ff;
    font-size: 1rem;
    border-radius: 10px 10px 10px 0;
    color: #FFFFFF;
    background: linear-gradient(to right, #FF812E, #FF2020);
    top: -1.5rem;
    right: -0.5rem;
}

.top-bar-login {
    width: 9rem;
    height: 3rem;
    /* background: linear-gradient(to right, #879FFF, #B487FF, #FDDBBA); */
    border-radius: 2.3rem;
    display: flex;
    align-items: center;
    padding: 0 0.5rem;
    color: #333333;
    font-size: 1rem;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    /* flex-direction:row-reverse; */
    cursor: pointer;
    margin-right: 1.2rem;
}

.top-bar-login_img {
    width: 2rem;
    height: 2rem;
}
.top-bar-login_img1 {
    width: 3rem;
    height: 3rem;
}

.top-bar-login1 {
    width: 4.7rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 0.5rem;
}

.top-vip {
    position: absolute;
    bottom: 0.5rem;
    right: 1.5rem;
    font-size: 0.8rem;
    color: #FFFFFF;
    padding: 0.1rem 0.5rem;
    /* background-color: #B4B4B4; */
    border-radius: 0.8rem;
}

.el-userInfo {
    min-width: 20rem !important;
    width: 26rem !important;
    padding: 0;
    /* position: absolute;
    right: 1rem;
    top: 5rem; */
}
</style>