<template>
  <div id="home">
    <top index="0" @login="login"></top>
    <div class="content">
      <!-- 左边导航栏 -->
      <homeLeft @openmyCreate="openmyCreate"></homeLeft>

      <!-- 右边主体 -->
      <div class="home_right">
        <!-- banner -->
        <banner @getbanner="getbanner"></banner>

        <homeLable ref="homeLable"></homeLable>
      </div>
    </div>
    <login ref="login"></login>
    <myCreate ref="myCreate"></myCreate>
    <gzhpopup></gzhpopup>
  </div>
</template>

<script>
import top from '@/components/top.vue'
import homeLeft from '@/components/home_left.vue';
import banner from '@/components/banner.vue';
import homeLable from '@/components/home_lable.vue';
import login from '@/components/login.vue';
import myCreate from '@/components/my_create.vue'
import gzhpopup from '@/components/gzhpopup.vue'
export default {
  components: {
    top, homeLeft, banner, homeLable, login, myCreate,gzhpopup
  },
  created() {

  },
  data() {
    return {

    }
  },
  methods: {
    // 登录
    login() {
      this.$refs.login.show()
    },
    // 我的创作
    openmyCreate() {
      this.$refs.myCreate.show()
    },
    getbanner(i) {
      if (i == 0) {
        this.$refs.homeLable.chang(0)
      } else if (i == 1) {
        this.$refs.homeLable.chang(2)
      } else if (i == 2) {
        this.$router.push({ path: '/vip' });
      }
    }

  }
}
</script>

<style>
#home {
  width: 128rem;
  height: 100vh;
  background: url('@/assets/image/bg.png') no-repeat;
  background-size: 100% 100%;
  padding-top: 5rem;
}

.content {
  width: 128rem;
  height: 90vh;
  margin-top: 1vh;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
}

.home_right {
  width: 118rem;
}
</style>