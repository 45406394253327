<template>
    <div id="card-box">
        <div class="title">{{ title }}</div>
        <div class="card_grade" v-for="(item, i) in list" :key="i">
            <div :class="gradeIndexL[0] == i ? 'card_grade1' : 'card_grade1 card_grade1_1'" @click="grade(i)">
                {{ item.title }}</div>
            <div class="card_grade2" v-if="gradeIndex == i">
                <div :class="gradeIndexL[0] == i && gradeIndexL[1] == g ? 'card_grade3' : 'card_grade3 card_grade4'"
                    v-for="(grade, g) in item.grade" :key="g" @click="grade1(i, g)">
                    {{ grade }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        // 标题
        title: {
            type: String,
            default: '年级',
        },
    },
    created() {
        this.$emit("getGrade", { grade: `小学${this.list[0].grade[0]}` });
    },
    data() {
        return {
            list: [
                {
                    title: '小学',
                    grade: ['一年级', '二年级', '三年级', '四年级', '五年级', '六年级']
                },
                {
                    title: '初中',
                    grade: ['初一', '初二', '初三']
                },
                {
                    title: '高中',
                    grade: ['高一', '高二', '高三']
                },
                {
                    title: '大学',
                    grade: ['大一', '大二', '大三', '大四']
                }
            ],
            gradeIndex: 0,
            gradeIndexL: [0, 0]

        }
    },
    methods: {
        grade(i) {
            this.gradeIndex = i
        },
        grade1(i, g) {
            this.gradeIndexL = [i, g]
            let item = ''
            if (i == 0) {
                item = `小学${this.list[i].grade[g]}`
            } else {
                item = this.list[i].grade[g]
            }

            this.$emit("getGrade", { grade: item });
        },
    }
}
</script>

<style>
.card_grade1 {
    border: 1px solid #409EFF;
    font-size: 1.2rem;
    padding: 0.8rem;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    color: #409EFF;
}

.card_grade1_1 {
    border: 1px solid #DDDDDD;
    color: #666666;
}

.card_grade2 {
    margin-top: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.card_grade3 {
    width: 6rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 0.5rem;
    color: #FFFFFF;
    background-color: #409EFF;
    border: 2px solid #92C8FF;
    margin-right: 1rem;
    margin-bottom: 1rem;
    cursor: default;
}

.card_grade4 {
    color: #666666;
    background-color: #FAFAFA;
    border: 2px solid #EEEEEE;
}
</style>